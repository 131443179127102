export default defineNuxtPlugin((nuxtApp) => {
  let enabled = false
  function initSwiper(call) {
    if (!enabled) {

      import('swiper/element/bundle').then(({ register }) => {
        register();
        import("swiper/css")
        import("swiper/css/navigation")
        import("swiper/css/pagination")
      });
      enabled = true
    }
    call && call()
  }

  nuxtApp.provide('initSwiper', initSwiper)
});
