// plugins/priceFormatter.js
import { useMainStore } from "../store/index";

export default defineNuxtPlugin((app) => {
  const store = useMainStore();
  const nuxtApp = useNuxtApp();

  // Retrieve currency and placement from the store or use defaults
  const currency =
    store &&
      store &&
      store.currentSite &&
      store.currentSite.default_currency_token
      ? store.currentSite.default_currency_token
      : "$";

  const placement =
    store &&
      store &&
      store.currentSite &&
      store.currentSite.default_currency_place
      ? store.currentSite.default_currency_place
      : "left";

  // Function to format the price with currency placement
  const formatPrice = (price) => {
    if (placement === "right") {
      return `${price} ${currency}`;
    } else {
      return `${currency} ${price}`;
    }
  };

  // Inject the formatPrice function into Vue instances, components, and context
  nuxtApp.provide("formatPrice", formatPrice);
  nuxtApp.provide("currency", currency);

  // check device os
  function checkDeviceOS() {
    if (navigator) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Check if the device is Android
      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // Check if the device is iOS
      if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return "iOS";
      }

    }

    // If neither Android nor iOS
    return "Unknown";
  }

  nuxtApp.provide("checkDeviceOS", checkDeviceOS);

  // get key by value
  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  nuxtApp.provide("getKeyByValue", getKeyByValue);

  // query params
  function convertQueryParams(params) {
    return Object.keys(params)
      .map(key => {
        if (Array.isArray(params[key])) {
          return params[key].map(val => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
        } else if (typeof params[key] === 'object') {
          return Object.keys(params[key])
            .map(subKey => `${encodeURIComponent(key + '[' + subKey + ']')}=${encodeURIComponent(params[key][subKey])}`)
            .join('&');
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      .join('&');
  }

  nuxtApp.provide("convertQueryParams", convertQueryParams);

});
