import { useMainStore } from "~/store";

export default defineNuxtPlugin((nuxtApp) => {
  if (!import.meta.browser) return false;
  const staticArr = ["about-us", "news"];
  const arr = ["country-products-slug"];
  const store = useMainStore();
  const router = useRouter();

  let enabledXyz = false;

  if (store && store.pageLinks) {
    const pageName = nuxtApp.$getKeyByValue(
      store.pageLinks,
      router.currentRoute.value.params.page
    );


    if (
      (staticArr.includes(pageName) ||
        arr.includes(router.currentRoute.value.name)) &&
      !enabledXyz
    ) {
      import("@animxyz/vue3").then((VueAnimXYZ) => {
        import("@animxyz/core").then((c) => {
          enabledXyz = true;
          nuxtApp.vueApp.use(VueAnimXYZ.default);
        });
      });
    }
    nuxtApp.hook("page:start", () => {
      const dynamicName = nuxtApp.$getKeyByValue(
        store.pageLinks,
        router.currentRoute.value.params.page
      );
      if (
        (staticArr.includes(dynamicName) ||
          arr.includes(router.currentRoute.value.name)) &&
        !enabledXyz
      ) {
        import("@animxyz/vue3").then((VueAnimXYZ) => {
          import("@animxyz/core").then((c) => {
            enabledXyz = true;
            nuxtApp.vueApp.use(VueAnimXYZ.default);
          });
        });
      }
    });
  }

  function initXyzAnim() {
    import("@animxyz/vue3").then((VueAnimXYZ) => {
      import("@animxyz/core").then((c) => {
        enabledXyz = true;
        nuxtApp.vueApp.use(VueAnimXYZ.default);
      });
    });
  }

  nuxtApp.provide('initXyzAnim', initXyzAnim)
});
